/* eslint-disable implicit-arrow-linebreak */
 export const baseUrl = 'https://admin.ftpd.fondationtunisie.org';
//export const baseUrl = 'http://localhost:1337';

/* ---------------------- foreign donations table API END POINT--------------------- */
export const API_FOREIGN_DONATIONS = (language) =>
  `${baseUrl}/api/our-foreign-donations?sort[0]=year%3AASC&[populate]=status&locale=${language}`;

/* ---------------------- financial statement table API END POINT--------------------- */
export const API_FINANCIAL_STATEMENT = (language) =>
  `${baseUrl}/api/our-financial-statements?sort[0]=year%3AASC&[populate]=report&locale=${language}`;
/* --------------- API for uploading files : images / pdfs ... -------------- */
export const API_UPLOADS = (file) => `${baseUrl}${file}`;

/* -------------------------- Presses API END POINT ------------------------- */
export const API_PRESSES = (language) =>
  `${baseUrl}/api/presses?[populate]=images&locale=${language}`;
/* --------------------------- API NEWS END POINT -------------------------- */
export const API_NEWS = (language) => {
  return `${baseUrl}/api/newss?[populate]=image&locale=${language}`;
};

export const API_NEWS_DETAIL = (language, id = undefined) => {
  return `${baseUrl}/api/newss/${id}/?[populate]=image&locale=${language}`;
};

export const API_TEAM = (language) =>
  `${baseUrl}/api/team-members?[populate]=image&locale=${language}&sort=order`;

export const API_TEAM_GROUP = (language) =>
  `${baseUrl}/api/team-groups?locale=${language}&populate=team_members.image&sort=order`;

export const API_VISITS = `${baseUrl}/api/website-traffics`;

/* ------------------------- API PARTNERS END POINT ------------------------- */
export const API_PARTNERS = (language) =>
  `https://admin.ftpd.fondationtunisie.org/api/partners?[populate]=*&locale=${language}&pagination[page]=1&pagination[pageSize]=100`;
